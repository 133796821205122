import type { RouteRecordRaw } from "vue-router";
import type { RouterConfig } from "@nuxt/schema";
// 👉 Redirects
const redirects: RouteRecordRaw[] = [];
const { scrolled } = useScrollStateState()

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: (scannedRoutes) => [...redirects, ...scannedRoutes],
  // scrollBehaviorType: 'smooth',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    if (to.hash && to.path == from.path) {
      scrolled.value = false;
      const el = document.querySelector(to.hash);
      return { top: el?.offsetTop, left: 0, behavior: "smooth" };
    }

    return {
      top: 0,
      left: 0,
    };
  },
};
