import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_w2VlvAEcpa from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import index_D6fDksuOQB from "/app/plugins/casl/index.ts";
import index_kyvreQaQna from "/app/plugins/vuetify/index.ts";
import index_7axDEECAiz from "/app/plugins/i18n/index.ts";
import index_pJsEXjjc09 from "/app/plugins/iconify/index.ts";
import gsap_qE0fizvEy0 from "/app/plugins/gsap.ts";
import ldrs_jziyvkIXgZ from "/app/plugins/ldrs.ts";
import layouts_mPotmY5lsV from "/app/plugins/layouts.ts";
import mitt_S0QU5gJPTl from "/app/plugins/mitt.ts";
import webfontloader_client_iX85i8j6U3 from "/app/plugins/webfontloader.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  plugin_w2VlvAEcpa,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  index_D6fDksuOQB,
  index_kyvreQaQna,
  index_7axDEECAiz,
  index_pJsEXjjc09,
  gsap_qE0fizvEy0,
  ldrs_jziyvkIXgZ,
  layouts_mPotmY5lsV,
  mitt_S0QU5gJPTl,
  webfontloader_client_iX85i8j6U3
]