import { default as ecosystemzXppjlw2j2Meta } from "/app/pages/ecosystem.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as partnersEg1L314k67Meta } from "/app/pages/partners.vue?macro=true";
export default [
  {
    name: ecosystemzXppjlw2j2Meta?.name ?? "ecosystem",
    path: ecosystemzXppjlw2j2Meta?.path ?? "/ecosystem",
    meta: ecosystemzXppjlw2j2Meta || {},
    alias: ecosystemzXppjlw2j2Meta?.alias || [],
    redirect: ecosystemzXppjlw2j2Meta?.redirect,
    component: () => import("/app/pages/ecosystem.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenanceM1L3mPagESMeta?.name ?? "maintenance",
    path: maintenanceM1L3mPagESMeta?.path ?? "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    alias: maintenanceM1L3mPagESMeta?.alias || [],
    redirect: maintenanceM1L3mPagESMeta?.redirect,
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: partnersEg1L314k67Meta?.name ?? "partners",
    path: partnersEg1L314k67Meta?.path ?? "/partners",
    meta: partnersEg1L314k67Meta || {},
    alias: partnersEg1L314k67Meta?.alias || [],
    redirect: partnersEg1L314k67Meta?.redirect,
    component: () => import("/app/pages/partners.vue").then(m => m.default || m)
  }
]