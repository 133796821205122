export default {
  blank: () => import("/app/layouts/blank.vue").then(m => m.default || m),
  "components-default-layout-with-horizontal-nav": () => import("/app/layouts/components/DefaultLayoutWithHorizontalNav.vue").then(m => m.default || m),
  "components-default-layout-with-vertical-nav": () => import("/app/layouts/components/DefaultLayoutWithVerticalNav.vue").then(m => m.default || m),
  "components-footer": () => import("/app/layouts/components/Footer.vue").then(m => m.default || m),
  "components-nav-bar-notifications": () => import("/app/layouts/components/NavBarNotifications.vue").then(m => m.default || m),
  "components-navbar-shortcuts": () => import("/app/layouts/components/NavbarShortcuts.vue").then(m => m.default || m),
  "components-navbar-theme-switcher": () => import("/app/layouts/components/NavbarThemeSwitcher.vue").then(m => m.default || m),
  "components-top-app-bar": () => import("/app/layouts/components/TopAppBar.vue").then(m => m.default || m),
  "components-megamenu-community-megamenu": () => import("/app/layouts/components/megamenu/CommunityMegamenu.vue").then(m => m.default || m),
  "components-megamenu-developer-megamenu": () => import("/app/layouts/components/megamenu/DeveloperMegamenu.vue").then(m => m.default || m),
  "components-megamenu-ecosystem-megamenu": () => import("/app/layouts/components/megamenu/EcosystemMegamenu.vue").then(m => m.default || m),
  "components-megamenu-use-z-chains-megamenu": () => import("/app/layouts/components/megamenu/UseZChainsMegamenu.vue").then(m => m.default || m),
  default: () => import("/app/layouts/default.vue").then(m => m.default || m)
}