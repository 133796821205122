export default defineNuxtRouteMiddleware(() => {
  if (process.server) {
    const event = useRequestEvent();

    if (event) {
      const req = event.node.req;
      const res = event.node.res;

      const shouldCache = shouldCacheResponse(req, res);

      if (shouldCache) {
        const ttl = 86400; // 24 hours
        res.setHeader("Cache-Control", `max-age=${ttl}, public`);
      }
    }
  }
});

function shouldCacheResponse(request, response) {
  const config = useRuntimeConfig();
  const environment = ["staging", "production"];

  if (!environment.includes(config.environment)) {
    return false;
  }

  if (request.method !== "GET") {
    return false;
  }

  if (response.statusCode < 200 && response.statusCode >= 300) {
    return false;
  }

  return true;
}
