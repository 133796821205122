{
  "homepage": {
    "title": "比一般的加密货币交易所更快、更好、更强",
    "subtitle": "照顾病人、跟随病人是很重要的，但它会发生在这样一个有很多工作和痛苦的时候。",
    "left_button_content": "大",
    "right_button_content": "大"
  },
  "navbar": {
    "use_zchains": "Use ZChains",
    "get_zcd": "Get ZCD",
    "ecosystem": "Ecosystem",
    "developer": "Developer",
    "community": "Community",
    "usezchains_first_title": "Send, Receive & Store ZChains Assets",
    "zwallet": "Zwallet",
    "usezchains_second_title": "Transfer Assets To & From ZChains",
    "zwap": "Zwap",
    "usezchains_third_title": "Explore Transactions on ZChains",
    "zc_scan": "ZCScan",
    "ecosystem_first_title": "Explore dApps Built on ZChains",
    "overview": "Overview",
    "ecosystem_second_title": "Official dApps",
    "fractionZ": "FractionZ",
    "developer_first_title": "Built on ZChains",
    "documentation": "Documentation",
    "developer_second_title": "Repositories",
    "github": "Github",
    "developer_third_title": "Vision & Technical Implementation",
    "whitepaper": "Whitepaper",
    "community_first_title": "Get Involved",
    "community_hub": "Community Hub",
    "blog": "Blog",
    "press": "Press",
    "start_building": "Start Building",
    "language": "Language",
    "theme": "Theme",
    "partners": "Partners"
  },
  "community": {
    "our_community": "Our Community",
    "be_part_of_the_community": "Be Part of the Community",
    "ready_to_embark_desc": "Ready to embark on this exciting journey together? Join us today and become part of our dynamic community!",
    "telegram": "Telegram",
    "telegram_content": "News and updates.",
    "twitter": "X.com",
    "twitter_content": "Follow the conversation.",
    "discord": "Discord",
    "discord_content": "Chat in real time.",
    "instagram": "Instagram",
    "instagram_content": "Discover the latest market insights.",
    "youtube": "Youtube",
    "youtube_content": "Watch must-see videos."
  },
  "ecosystem": {
    "official_dapps": "Official dApps",
    "join_the_zchains_ecosystem": ["Join the ", "ZChains ", "Ecosystem"],
    "discover_more": "Discover more",
    "discover_top_dapps_desc": "Discover top dApps built on ZChains, including our official dApps.",
    "coming_soon": "Coming Soon",
    "zwap": "Zwap",
    "zwap_desc": "Swap and Bridge assets between ZChains and other blockchain networks, including Ethereum.",
    "tokenization": "Tokenization",
    "tokenization_desc": "This section will be explain the description of dApps.",
    "defi": "Defi",
    "dex": "Dex",
    "rwa": "Rwa",
    "ecosystem_hero_title": ["ZChains ", "Ecosystem"],
    "ecosystem_hero_subtitle": ["Discover the best dApps built on ", "ZChains."],
    "build_your_dapps": "Build Your DApps",
    "third_section_title": ["Unleash the Future", " with your dApp"],
    "third_section_desc": "Build your dApp to unlock your potential, embarking on a transformative journey toward success.",
    "build_now": "Build Now",
    "explore_dapps": "Explore DApps",
    "second_section_title": ["ZChains ", "dApps"],
    "second_section_subtitle": "Empowering innovators and builders shaping the forefront of the blockchain era."
  },
  "video": {
    "pause_video": "暂停播放",
    "play_video": "播放视频"
  },
  "zchains": {
    "env_compatible_l1": "EVM - COMPATIBLE - L1",
    "build_on_zchains": ["Build on ", "ZChains"],
    "content": "An interoperable L1 network driven by its ecosystem and focused in delivering experience-based services.",
    "scroll_to_start": "Scroll to start"
  },
  "description": {
    "introduction": "Introduction",
    "content": "ZChains is designed to be a high-performance network that builds on the Proof of Stake (PoS) consensus. With EVM compatibility, ZChains allows seamless integration with existing Ethereum-based dApps."
  },
  "limitless": {
    "empowering_businesses": "Empowering Businesses",
    "limitless_possibilities": ["Limitless ", "Possibilities"],
    "build_any_dapps_desc": "Build any dApps you want, any way you want on the lightning fast, interoperable ZChains.",
    "rwa_tokenization_ready": "RWA Tokenization Ready",
    "tokenize_real_world_desc": "Tokenize Real World Assets and create a virtual investment vehicle on the blockchain",
    "journey_to_web3": "Journey to Web3",
    "build_web3_games_desc": "Build Web3 games, NFTs and revolutionary dApps that bring exciting ways for users to be in control",
    "transparent_defi": "Transparent DeFi",
    "build_any_decentralized_desc": "Build any Decentralized Finance apps ranging from wallets to full fledge exchanges"
  },
  "creating": {
    "creating_a_sustainable_future": "Creating A Sustainable Future",
    "content": "ZChains is empowered by its native token, ($ZCD). Use ZCD to play Web3 games, mint NFTs,  fund DeFi dApps, stake to earn rewards and more.",
    "total_supply": "Total Supply",
    "team_holding": "Team Holding",
    "foundation": "Foundation",
    "marketing": "Marketing",
    "cex_allocation": "CEX Allocation",
    "the_power_of_ZCD": ["The Power ", "of ZCD"],
    "buy_now": "Buy Now"
  },
  "maintenance": {
    "upgrading_in_progress": "升级进行中",
    "we_will_be_back_shortly": "我们很快就会回来"
  },
  "error": {
    "error_message": "错误信息",
    "back_to_home": "返回主页"
  },
  "mentioned":{
    "our_coverage":"Our coverage",
    "zchains_was_mentioned_on":"ZChains was mentioned on"
  },
  "zchains_partners": {
    "zchains_partners": ["ZChains ", "Partners"],
    "hero_subtitle": "Seamlessly integrate with top-tier platforms and protocols.",
    "explore_collaborators": "Explore Collaborators",
    "second_section_subtitle": "Working towards our visions together.",
    "partner_with_us": "Partner With Us",
    "third_section_title": ["Unveil What's Next ", "With Our Partners"],
    "third_section_subtitle": "Join forces with ZChains as our partners to unveil the next frontier of innovation. Together, we'll explore new possibilities and shape the future.",
    "become_a_partner": "Become A Partner"
  },
  "footer": {
    "twitter": "X",
    "instagram": "Instagram",
    "telegram": "Telegram",
    "youtube": "Youtube",
    "discord": "Discord",
    "zfinances": "ZFinances",
    "bitmart": "BitMart",
    "lbank": "LBank",
    "mexc": "MEXC",
    "p2b": "P2B",
    "documentation": "Documentation",
    "github": "Github",
    "blog": "Blog",
    "block_explorer": "Block Explorer",
    "ecosystem": "Ecosystem",
    "bridge": "Bridge",
    "swap": "Swap",
    "trade": "Trade",
    "resources": "Resources",
    "use_zchains":"Use ZChains",
    "social": "Social"
  }
}
