<script setup lang="ts">
import type { NuxtError } from 'nuxt/app'
import { themeConfig } from "@themeConfig";
import { VNodeRenderer } from "@layouts/components/VNodeRenderer";
import { useTheme } from "vuetify";
import { hexToRgb } from "@layouts/utils";

const props = defineProps<{
  error: NuxtError
}>()

const { global } = useTheme();
const t = useI18n();

const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <VApp
    theme="dark"
    :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`"
  >
    <NuxtLayout name="blank">
      <div class="container d-flex flex-column">
        <div class="app-logo logo-container">
          <VNodeRenderer :nodes="themeConfig.app.logo" class="logo-image" />
        </div>

        <div class="text-ltrenovate text-h4">
          <p>
            <span>var </span>
            <span class="text-primary"> {{ $t("error.error_message") }} </span>
            <span> = () => {</span>
          </p>

          <p class="text-center mx-5">
            return
            <span class="text-primary"> "{{ error.statusCode }} {{ error.statusMessage }}"</span>
            <span> ;</span>
          </p>

          <p>}</p>
        </div>

        <div class="back-button">
          <VBtn @click="handleError"> {{ $t("error.back_to_home") }} </VBtn>
        </div>
      </div>
    </NuxtLayout>
  </VApp>
</template>

<style lang="scss" scoped>
.container {
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--v-theme-surface));
}

.logo-container {
  position: absolute;
  top: 15%;
}

.logo-image {
  transform: scale(1.5);
}

.back-button {
  position: absolute;
  bottom: 25%;
}
</style>
