import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Draggable } from 'gsap/Draggable'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable)

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.provide('gsap', gsap)
  nuxtApp.provide('ScrollTrigger', ScrollTrigger)
  nuxtApp.provide('ScrollToPlugin', ScrollToPlugin)
  nuxtApp.provide('Draggable', Draggable)
})
